// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  CM_BASE_API_URL: "https://kusist.kalingauniversity.edu.in", // production
  // CM_BASE_API_URL: "http://localhost:5239", // Local
  defaultauth: 'fackbackend',
  firebaseConfig: {
    apiKey: 'AIzaSyAIrRUP8z39yFhjsfnLsxwDhzxzAguzHvI',
    authDomain: 'vixon-angular.firebaseapp.com',
    databaseURL: '',
    projectId: 'vixon-angular',
    storageBucket: 'vixon-angular.appspot.com',
    messagingSenderId: '822277458131',
    appId: '1:822277458131:web:5ec3bee2543b96567aac26',
    measurementId: 'G-YPD27EXC6H'
  }
};


