<!-- Begin page -->
<div id="layout-wrapper">
    <!-- Side Bar -->
    <app-sidebar></app-sidebar>
    <!-- Top Bar -->
    <app-topbar (mobileMenuButtonClicked)="onToggleMobileMenu()"></app-topbar>
    <div class="main-content">

        <div class="page-content">
            <div class="container-fluid">
                <!-- Main Content -->
                <router-outlet></router-outlet>
            </div>
        </div>
        <!-- Footer -->
        <app-footer></app-footer>
    </div>
</div>
